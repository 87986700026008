// layout/footer.scss

@mixin footer-social {
  display: flex;
  width: 60%;
  margin: 0 auto;
  justify-content: space-around;
}

footer {
  position: relative;
  z-index: 100;
}

.footer-content {
  //@include debug;
  @include paddingY($spacing-y * 2);
  width: 40%;
  text-align: center;

  i {
    color: $color-grey-light;
  }

  .footer-logo {
    margin-bottom: 1.2rem;

    img {
      max-width: 50%;
    }
  }

  .footer-sitemap {

    & > * {
      padding: $spacing-y * .8 0;
    }

    li:first-child {
      padding-top: $spacing-y * 1.6;
    }

    li:last-child {
      padding-bottom: $spacing-y * 1.6;

      a {
        color: $color-primary;
        &:hover {
          border-bottom: 2px solid $color-primary;
        }
      }
    }


    &:before,
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background: $color-grey-light;
    }
  }

  .footer-infos {
    padding: $spacing-y 0;
    color: $color-grey-light;

    a {
      color: inherit;
      text-decoration: none;
    }

    & > * {
      padding: $spacing-y * .5 0;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background: $color-grey-light;
    }

    li:last-child, {
      padding-bottom: $spacing-y * 1.6;
    }
  }

  .footer-socials--mobile {
    @include footer-social;
  }

  .footer-socials--desktop {
    display: none;
  }


  @media screen and (min-width: $tablet) {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .footer-logo {
      text-align: left;
      width: 20%;
    }

    .footer-sitemap,
    .footer-infos {
      &::after,
      &::before {
        display: none;
      }
    }

    .footer-sitemap {
      $border: 3px solid $color-grey-light;
      width: 40%;
      border-left: $border;
      border-right: $border;
    }

    .footer-infos {
      width: 20%;
    }

    .footer-socials--mobile {
      display: none;
    }

    .footer-socials--desktop {
      @include footer-social;
      width: 100%;
      margin-top: $spacing-y * 1.5;
      justify-content: flex-start;

      li {
        padding-right: $spacing-x * 2;
      }

      i {
        font-size: 20px;
      }
    }
  }
}

.sector {
  li {

    a {
      text-decoration: none;
      color: $white;
      font-size: 1.5rem;

      &:hover {
        border-bottom: 2px solid $white;
      }
    }
  }
}