//pages/_vision.scss

//-------------------------------------------------------------------------
// Vision - main
//-------------------------------------------------------------------------
.vision-main {
  background: url(/images/vision/bg-vision-mobile.jpg) top center no-repeat scroll;
  background-size: cover;

  @media screen and (min-width: $tablet) {
    background: url(/images/vision/bg-vision.jpg) top right no-repeat fixed;
    background-size: cover;
  }

  h2 { @include subtitle(); }

  h3 {
    text-transform: uppercase;
    margin-top: $spacing-y;
    margin-bottom: $spacing-y * .5;
    color: $color-primary;
  }

  p { padding-bottom: $spacing-y; }
}

//-------------------------------------------------------------------------
// Section Vision Hero
//-------------------------------------------------------------------------
.vision-hero {
  h1 {
    @include title-lg;
    padding-top: $header-h-mobile + 4rem;
    padding-bottom: 4rem;
  }

  @media screen and (min-width: $tablet) {
    h1 { font-size: 5rem; }
  }

  @media screen and (min-width: $desktop) {
    h1 {
      font-size: 7rem;
      padding-bottom: 14rem;
    }
  }
}

//-------------------------------------------------------------------------
// Section Vision common for legrey-journey, and-co, -code
//-------------------------------------------------------------------------
.vision-legrey-journey {
  margin-bottom: $spacing-y;

  em {
    display: inline-block;

    &::after {
      content: "";
      background: $color-primary;
      color: $color-primary;
      display: inline-block;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      margin-bottom: .2rem;
      margin-left: .5rem;
      margin-right: .2rem;
    }
  }
}

.vision-legrey-journey,
.vision-legrey-and-co {
  padding-bottom: $spacing-y * 2;

  @media screen and (min-width: $tablet) {
    p { width: 70%; }
  }
  @media screen and (min-width: $desktop) {
    p { width: 46%; }
  }
}

//-------------------------------------------------------------------------
// Section Vision legrey-journey
//-------------------------------------------------------------------------
.vision-legrey-journey {
  @media screen and (min-width: $tablet) {
    h2 { max-width: 100%; }
  }
}

//-------------------------------------------------------------------------
// Section Vision legrey-and-co
//-------------------------------------------------------------------------
.vision-legrey-and-co {
  h2 { max-width: 30%; }

  figure {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .shape-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .shape-img {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
    }
  }

  @media screen and (min-width: $tablet) {
    text-align: right;
    margin-left: auto;
    h2 { max-width: 100%; }
    p { margin-left: auto; }
  }
}

.vision-legrey-expertise {
  @extend %heading;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 2px;
  font-size: 1.25rem;
  padding-bottom: 6rem;
  color: $white;

  p {
    @media screen and (min-width: $tablet) {
      font-size: 1.5rem;
    }

    @media screen and (min-width: $desktop) {
      font-size: 2rem;
    }

    &:last-of-type {
      color: $color-primary;
    }
  }
}

//-------------------------------------------------------------------------
// Section Vision legrey-code
//-------------------------------------------------------------------------
.vision-legrey-code {
  @include paddingY($spacing-y * 4);

  @media screen and (min-width: $tablet) {
    @include paddingY($spacing-y * 6);
  }
}
