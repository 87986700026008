// pages/demarche.scss

.demarche-main {
  background: url(/images/demarche/bg-demarche-mobile.jpg) no-repeat scroll bottom center;
  background-size: cover;

  .demarche-container {
    @include container;
    padding-bottom: 10rem;

    & > * {
      padding-bottom: $spacing-y * 2;
    }
  }

  @media screen and (min-width: $tablet) {
    background: url(/images/demarche/bg-demarche-desktop.jpg) bottom center no-repeat fixed;
    background-size: cover;
  }

  @media screen and (min-width: $desktop) {
    .demarche-container {
      padding-bottom: 20rem;
    }
  }
}

.demarche-hero {
  h1 {
    @include title-lg;
    padding-top: $header-h-mobile + 4rem;
    padding-bottom: 2rem;
  }

  @media screen and (min-width: $tablet) {
    h1 { font-size: 5rem; }
  }

  @media screen and (min-width: $desktop) {
    h1 { font-size: 7rem; }
  }
}

.demarche-connect {
  .btn {
    margin-top: 1rem;
  }

  ul li {
    display: inline-block;

    &::before {
      content: "";
      background: $color-primary;
      color: $color-primary;
      display: inline-block;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      margin-bottom: .2rem;
      margin-right: .2rem;
    }
  }

  @media screen and (min-width: $tablet) {
    margin-left: auto;
    max-width: 50%;
    text-align: right;

    ul li {
      font-size: 1.1rem;

    }
  }
}

.demarche-hero--sector {

  h1 {
    width: 80%;

    @media screen and (max-width: $mobile) {
      font-size: 10vw;
    }
  }

  p {
    width: 80%;

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }
}