// components/_boxes.scss

//-------------------------------------------------------------------------
// BOXES
//-------------------------------------------------------------------------
// Place components like boxes, panels etc here


.large-content-box {
  font-size: $font-size-base;
  font-family: $font-primary-bold;
  color: $white;
  line-height: 1.2;
  letter-spacing: 1px;
  // @include paddingX($content-gutter);
  @include paddingY($spacing-y * 2);

  ul > li {
    display: inline-block;
    position: relative;

    &::before {
      content: "";
      background: $white;
      color: $white;
      display: inline-block;
      border-radius: 50%;
      width: 4px; height: 4px;
      margin: 0.2em 0.5em 0.2em 0.2em;

      @media screen and (min-width: $tablet) {
        width: 4px; height: 4px;
      }

      @media screen and (min-width: $tablet) {
        width: 6px; height: 6px;
      }

      @media screen and (min-width: $desktop) {
        width: 8px; height: 8px;
      }
    }
  }

  p, h1 {
    margin-bottom: 1.5rem;
  }

  h1 {
    font-weight: normal;
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.centered {
    text-align: center;
  }

  @media screen and (min-width: $tablet) {
    font-size: 1.5rem;
    @include paddingY($spacing-y * 4);

    p, h1 {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: $desktop) {
    font-size: 2rem;

    p, h1 {
      font-size: 2rem;
    }
  }
}

.titled-box {
  h2 {
    @include subtitle();
  }

  @media screen and (min-width: $tablet) {
    p {
      max-width: 50%;
    }

    &.align-right {
      h2, p {
        text-align: right;
        margin-left: auto;
      }
    }
  }
}
