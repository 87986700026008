$contentWidthLimit: 1720px;

@mixin contentWidth($method) {
  #{$method}-left: calc((100% - #{$contentWidthLimit}) / 2);
  #{$method}-right: calc((100% - #{$contentWidthLimit}) / 2);
}


@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin paddingX($size) {
  padding-left: $size;
  padding-right: $size;
}

@mixin paddingY($size) {
  padding-top: $size;
  padding-bottom: $size;
}

@mixin marginX($size) {
  margin-left: $size;
  margin-right: $size;
}

@mixin marginY($size) {
  margin-top: $size;
  margin-bottom: $size;
}

@mixin container() {
  margin: 0 auto;
  @include paddingX($content-gutter);
  max-width: $mobile-sm;

  @media screen and (min-width: $tablet) {
    max-width: $tablet;
    @include paddingX($content-gutter * 3);
  }

  @media screen and (min-width: $desktop) {
    max-width: $desktop;
    @include paddingX($content-gutter * 10);
  }

}


//----------------------------------------------------
// typography mixins
//----------------------------------------------------
// use these in components when you don't want to use
// classes in the html

@mixin title() {
  font-family: $font-primary-bold;
  text-transform: uppercase;
  display: block;
}

@mixin title-lg() {
  @include title;
  font-size: 2.5rem; // 40px
  letter-spacing: 2px;
  line-height: 1;

  @media screen and (min-width: $tablet) {
    font-size: 4rem; // 64px
  }

  @media screen and (min-width: $desktop) {
    font-size: 6rem; // 96px
    line-height: 1;
    letter-spacing: 6px;
  }
}


@mixin subtitle($color: $color-primary) {
  @include title;
  font-size: 1.5rem;
  padding-bottom: $spacing-y;
  letter-spacing: 2px;
  color: $color;

  @media screen and (min-width: $tablet) {
    font-size: 2rem;
  }

  @media screen and (min-width: $desktop) {
    font-size: 3rem;
  }
}
