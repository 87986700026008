.btn {
  display: inline-block;
  font-size: $font-size-xs;
  font-family: $font-primary-bold;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  //padding: 8px;
  z-index: 2;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  & > span {
    display: block;
  	padding: 10px 20px;
  	background: #0f7c67;
  	-webkit-transition: -webkit-transform 0.3s;
  	-moz-transition: -moz-transform 0.3s;
  	transition: transform 0.3s;
    background: $color-primary;
    color: $white;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    background: #eeeeee;
    color: $color-primary;
    content: attr(data-hover);
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transform: translateX(-25%);
  }

  // Only trigger animation for touch enable device.
  @media (hover: hover) and (pointer: fine) {
    &:hover span,
    &:focus span {
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      transform: translateX(100%);
    }

    &:hover::before,
    &:focus::before {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      transform: translateX(0%);
    }
  }

  &.white {
    &::before {
      background: #33e48d;
      color: $white;
    }

    & > span {
      color: $color-primary;
      background: $white;
    }
  }

  @media screen and (min-width: $tablet) {
    font-size: 1.3rem;
  }
}
