// pages/contact.scss

@mixin contact-title {
  @include subtitle;
  color: $color-primary;
  font-size: 24px;
  letter-spacing: 1px;
  padding-bottom: 0;
}

//-------------------------------------------------------------------------
// Section contact hero
//-------------------------------------------------------------------------
.contact-hero {
  padding-top: $header-h-mobile + 6rem;
  padding-bottom: $spacing-y;

  h1 {
    @include contact-title;
  }
}


//-------------------------------------------------------------------------
// Common for forms
//-------------------------------------------------------------------------
input, textarea, .field-box, select {
  border: 1px solid $color-grey-dark;
  padding: .8em 1em;
  background-color: $black;
  width: 100%;
  color: $white;
  font-family: inherit;
  margin-top: .5rem;
  margin-bottom: $spacing-y;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background: url(/images/select-arrow.svg) no-repeat;
  background-size: 12px;
  background-position: calc(100% - 1rem) 50%;
  border-radius: 0;
}

option {
  background-color: $black;
  color: $white;
}

label {
  display: block;
  margin-bottom: .5rem;
}

fieldset {
  padding: 0;
}

.btn-container {
  padding-top: $spacing-y;
  text-align: right;
  margin-left: auto;
}

.full-width {
  grid-column: 1 / -1;
}

.end {
  justify-self: end;
}

.two-by-line {
  flex-basis: 100%;

  @media screen and (min-width: $desktop) {
    flex-basis: 48%;
  }
}

.area {
  flex-basis: 100%;
}


//-------------------------------------------------------------------------
// Section contact (form and infos)
//-------------------------------------------------------------------------
.contact {
  @include paddingY($spacing-y);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: $tablet) {
    flex-direction: row;
  }
}

.contact-form {
  order: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .full-name,
  .phone,
  .email,
  .subject,
  .cv,
  .btn-container {
    flex-basis: 100%;
  }

  @media screen and (min-width: $tablet) {
    flex-basis: 45%;
    order: 0;
  }

  @media screen and (min-width: $desktop) {
    flex-basis: 60%;
    order: 0;

    .full-name,
    .phone,
    .email,
    .subject,
    .cv {
      flex-basis: 40%;
    }
  }
}

.contact-infos {
  margin-bottom: $content-gutter * 4;
  font-size: $font-size-sm;

  /* [Code Block Disable]: Address second line refactor */

  // br {
  //   display: none;

  //   @media screen and (min-width: $tablet) {
  //     display: inline;
  //   }
  // }

  /* End of [Code Block Disable]: Address second line refactor */


  .row {
    flex-basis: 100%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: $tablet) {
    flex-basis: 55%;

    .row {
      flex-basis: 100%;
      margin-bottom: 2rem;
    }
  }

  @media screen and (min-width: $desktop) {
    flex-basis: 40%;
    max-width: 400px;
  }

  i {
    color: $color-primary;

    /* [Code Block Disable]: Address second line refactor */

    // margin-right: .4rem;

    // address second line icon
    // &.black {
    //   color: black;
    //   display: none;

    //   @media screen and (min-width: $tablet) {
    //     display: inline;
    //   }
    // }

    /* End of [Code Block Disable]: Address second line refactor */

    /* [Code Refactor]: Address second line refactor */
    position: absolute;
    left: 0;
    /* End of [Code Refactor]: Address second line refactor */
  }

  a {
    color: $color-grey-light;
    text-decoration: none;
    font-family: $font-primary-bold;
    font-weight: bold;
    letter-spacing: 0.7px;

    /* [Code Refactor]: Address second line refactor */
    position: relative;
    padding-left: $space-sm * 3;
     /* End of [Code Refactor]: Address second line refactor */

    &:nth-of-type(even) {
      margin-left: 2rem;
    }

    @media screen and (min-width: $tablet) {
      padding-left: $space-md * 2;
    }
  }

  @media screen and (min-width: $tablet) {
    padding-top: 2.5rem;
    padding-left: 2rem;
  }
}


//-------------------------------------------------------------------------
// Section career form and all
//-------------------------------------------------------------------------
.contact-career {

  p {
    margin-bottom: $spacing-y * 3;
  }
}

.career-title {
  @include contact-title;
  margin-bottom: $spacing-y * 2;
  cursor: pointer;

  &:after {
    content: '+';
    color: $white;
    padding-left: 1rem;
  }

  &.show {
    &:after {
      content: '-';
      color: $white;
      padding-left: 1rem;
    }
  }


}

.career-wrapper {
  @include paddingY($spacing-y * 3);
  @media screen and (min-width: $tablet) {
    max-width: 45%;
  }

  @media screen and (min-width: $desktop) {
    max-width: 60%;
  }

}

.career-group {
  display: none;

  &.show {
    display: block;
  }
}

.career-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.field-box {
  margin-top: .95rem;

  button {
    font-size: .7rem;
  }
}

// File Upload Button
.file-upload {
  display: flex;
  justify-content: space-between;
  color: $color-primary;
  align-items: center;
}

.file-upload__btn {
  cursor: pointer;
  position: relative;
  margin-right: $space-lg;

  &::after {
    content: "+";
    margin: $space-sm;
    font-size: 30px;
    line-height: 0;
    padding: 0;
    font-weight: bold;
    display: inline-block;
    transition: .5s ease-in-out;
    position: absolute;
  }

  &:hover {
    &::after {
      color: $white;
    }
  }
}