.form-success,
.form-fail {
  background: $black;
  padding-top: $header-h-mobile + 7rem;
  text-align: center;

  h1 {
    @include subtitle;
  }

  p:last-child {
    margin-bottom: 25rem;
  }
}
