//pages/_404.scss

.main-404 {
  padding-top: 12rem;
  background: $black;
  text-align: center;

  h1 {
    @include title-lg;
  }

  .btn {
    margin-bottom: 15rem;
  }

}
