@import url("https://p.typekit.net/p.css?s=1&k=rti0ews&ht=tk&f=19121.19123&a=15598843&app=typekit&e=css");

@font-face {
  font-family: "akzidenz-grotesk-med";
  src: url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "akzidenz-grotesk";
  src: url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

// sizes
$space-sm: 0.5rem;
$space-md: 1rem;
$space-lg: 1.5rem;

// colors
$white: #fff;
$black: #000000;
$color-primary: #26d07c;

$color-grey-dark: #53565a;
$color-grey-light: #d9d9d6;
$color-grey-light-2: #f2f2f2;

// FONT VARIABLES
$font-primary: "akzidenz-grotesk", sans-serif;
$font-primary-bold: "akzidenz-grotesk-med", sans-serif;


// font sizes
$font-size-xs: .75rem;        // 12px
$font-size-sm: .875rem;       // 14px
$font-size-default: 1rem;     // 16px
$font-size-md: 1.125rem;      // 18px
$font-size-lg: 1.25rem;       // 20px
$font-size-xl: 2.5rem;       // 40px
$font-size-xxl: 5rem;       // 80px


$font-size-base: $font-size-sm;   // since we're doing mobile first
$font-size-base-tablet: $font-size-default;
$font-size-base-desktop: $font-size-md;

// GUTTERS
$default-gutter: .625rem;

$content-gutter: $default-gutter;
$spacing-y: $default-gutter * 1.5;
$spacing-x: $default-gutter;

// site wide
$header-h-mobile: $spacing-y * 2;
$header-h-desktop: $spacing-y * 4;


// media query width
$mobile-sm: 320px;
$mobile: 560px;
$tablet: 768px;
//$desktop: 1024px;
$desktop: 1420px;
//$desktop-large: 1420px;
$desktop-x-large: 1920px;
