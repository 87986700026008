// portfolio.scss

//-------------------------------------------------------------------------
// Section Portfolio Hero
//-------------------------------------------------------------------------

.portfolio-hero {
  margin-bottom: 2rem;
  margin-top: $header-h-mobile + 7rem;

  h1, p {
    @include title;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 1px;
    @include paddingX($spacing-x);
  }

  h1 {
    margin-bottom: $spacing-y * 2;
    color: $color-primary;
  }

  p {
    color: $color-primary;
    padding-bottom: $spacing-y * 3;
  }

  @media screen and (min-width: $tablet) {
    margin-bottom: 2rem;
    margin-top: $header-h-mobile + 8rem;

    h1, p {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: $desktop) {
    margin-bottom: 5rem;
    margin-top: $header-h-mobile + 12rem;

    h1, p {
      font-size: 2rem;
    }
  }

}

.portfolio-footer {
  p {
    @include title;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 1px;
    @include paddingX($spacing-x);
    color: $color-primary;
    @include paddingY(6rem);

    @media screen and (min-width: $tablet) {
      font-size: 1.5rem;
    }

    @media screen and (min-width: $desktop) {
      font-size: 2rem;
    }
  }
}

//-------------------------------------------------------------------------
// Section Portfolio Images
//-------------------------------------------------------------------------
.portfolio-images {
  @include container();
  display: flex;
  flex-wrap: wrap;

  & > figure {
    line-height: 0;
    overflow: hidden;

    a {
      width: 100%;
      height: 100%;
      display: block;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 175px;

      &.-hover {
        @media screen and (min-width: $tablet) {
          transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
          opacity: .8;

          &:hover {
            opacity: 1;
            transform: scale(1.15);
          }
        }
      }
    }
  }

  .blank-grey {
    background: $color-grey-dark;
  }

  .blank-green {
    background: $color-primary;
  }

  .strom,
  .olan,
  .color-samples,
  .liv,
  .sirkel {
    //background: $white;
  }

  .flo,
  .oslo {
    //background: $color-primary;
  }

  .vinkel {
    //background: $color-grey-light;
  }

  @media screen and (min-width: $tablet) {
    & > figure {
      img {
        height: 275px;
      }
    }

    .strom {
      width: 55%;
    }

    .marche-jean-talon {
      width: 45%;
    }

    .flo,
    .oslo,
    .sven,
    .olan,
    .sven-2 {
      width: 100%;
    }

    .liv,
    .flo-2,
    .fjord-3 {
      width: 40%;
    }

    .fjord-2,
    .sven-3,
    .blank-green {
      width: 60%;
    }

    .vinkel,
    .color-samples {
      width: 50%;
    }

    .sirkel {
      width: 70%;
    }

    .blank-grey {
      width: 30%;
    }


  }

  @media screen and (min-width: $desktop) {
    & > figure img {
      height: 490px;
    }
  }

  .sven-4,
  .sven-2,
  .sven-3,
  .fjord,
  .fjord-2,
  .flo-2 {
    display: none;

    @media screen and (min-width: $tablet) {
      display: block;
    }
  }
}
