*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@mixin debug($color: magenta) {
  outline: 3px solid $color;
}

body {
  font-family: $font-primary;
  font-size: $font-size-base;
  color: $color-grey-light;
  line-height: 1.2;

  @media screen and (min-width: $tablet) {
    font-size: $font-size-base-tablet;
  }
}

img {
  max-width: 100%;
}

header {
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent linear-gradient(180deg, #000000 0%, #54545400 100%) 0 0 no-repeat padding-box;

  @media screen and (min-width: $tablet) {
    background: transparent;
  }

}

main {
  overflow-x: hidden;
}

@media screen and (min-width: $tablet) {
  p, a {
    font-size: 1.1rem;
  }
}

// layout
//-----------------------

.container {
  @include container();
}

// components
//-----------------------

.made-by {
  text-align: center;
  @include paddingY($spacing-y);
  p, a {
    font-size: 0.8rem;
  }

  a {
    text-decoration: none;
  }
}

.logo {
  width: 85px;
}


.bg-primary {
  background-color: $color-primary;
  color: $color-grey-light;
}

.bg-dark {
  background-color: $black;
  color: $color-grey-light;
}

.bg-grey-light {
  background-color: $color-grey-light-2;
  color: $color-grey-dark;
}
