@keyframes slideInLeft {
  0% {
    transform: translateX(-500px);
    -webkit-transform: translateX(-500px);
    -moz-transform: translateX(-500px);
    -o-transform: translateX(-500px);
    -ms-transform: translateX(-500px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(500px);
    -webkit-transform: translateX(500px);
    -moz-transform: translateX(500px);
    -o-transform: translateX(500px);
    -ms-transform: translateX(500px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInDown {
  0% {
    transform: translateY(500px);
    -webkit-transform: translateY(500px);
    -moz-transform: translateY(500px);
    -o-transform: translateY(500px);
    -ms-transform: translateY(500px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInDownSlow {
  0% {
    transform: translateY(100px);
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -o-transform: translateY(100px);
    -ms-transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInTop {
  0% {
    transform: translateY(-500px);
    -webkit-transform: translateY(-500px);
    -moz-transform: translateY(-500px);
    -o-transform: translateY(-500px);
    -ms-transform: translateY(-500px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.slideInLeft {
  animation-name: slideInLeft;
  -webkit-animation-name: slideInLeft;
}
.slideInRight {
  animation-name: slideInRight;
  -webkit-animation-name: slideInRight;
}
.slideInDown {
  animation-name: slideInDown;
  -webkit-animation-name: slideInDown;
}
.slideInDownSlow {
  animation-name: slideInDownSlow;
  -webkit-animation-name: slideInDownSlow;
}
.slideInTop {
  animation-name: slideInTop;
  -webkit-animation-name: slideInTop;
}
.fadeIn {
  opacity: 0;
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;

  @media screen and (max-width: $tablet - 1) {
    opacity: 1;
  }
}

/* Class to give to all elements you want to animate */
.anim {
  animation: none;
  visibility: hidden;

  @media screen and (max-width: $tablet - 1) {
    visibility: visible;
  }
}

/* Class that is applied to elements when the animation is triggered */
.running {
  visibility: visible;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}
