//pages/_portfolio-single.scss

.portfolio-single {

  .hero {
    padding-top: 7.5rem;
  }

  .content {

    // border: 1px solid red;
    position: relative;

    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (min-width: $tablet) {
      text-align: right;
    }

    h1 {
      @include subtitle;
      margin-top: 0;
      margin-bottom: $spacing-y;
      font-size: 1rem;
    }

    p {
      padding-bottom: $spacing-y;
    }
  }

  .description {

    // border: 1px solid green;

    padding-bottom: 3rem;
    margin-left: auto;

    // transition: 0.5s ease;

    @media screen and (min-width: $tablet) {

      &.uncut {
        position: absolute;
        bottom: 0;
      }

      &.center {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .bottom-band {
    width: 100%;
    height: 4rem;
    background-color: $black;
    position: relative;
    z-index: 2;
  }

  @media screen and (min-width: $tablet) {
    .hero {
      padding-top: 12.5rem;
    }

    .description {
      // position: fixed;
      right: calc((100% - 708px) / 2);
      max-width: 400px;

      @media screen and (min-width: $desktop) {
        right: calc((100% - 1220px) / 2);
        max-width: 600px;
      }

      p {
        width: 100%;
        margin-left: auto;
      }
    }

    .images {
      //padding-top: $spacing-y;
      order: -1;
      flex-basis: 40%;

      figure {
        margin-bottom: 4rem;

        &:last-of-type {
          margin-bottom: 0;
        }

        & > img {
          opacity: 0.5;
          transition: opacity 200ms ease-in-out;

          &.highlight {
            opacity: 1;
          }
        }
      }
    }
  }
}
