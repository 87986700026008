// _home.scss

//-------------------------------------------------------------------------
// Section Home - hero
//-------------------------------------------------------------------------

.home-hero {
  background: url(/images/home/hero.jpg) top center no-repeat;
  background-size: cover;
  padding-bottom: 9vw;
  padding-top: 17rem;

  p {
    @include subtitle;
    //max-width: 70%;
    align-self: center;
    padding-bottom: $spacing-y;
    font-weight: bold;
    margin: 0.67em 0;
  }

  @media screen and (min-width: $tablet) {
    padding-top: 10rem;
    p {
      margin-top: 4rem;
    }
  }

  @media screen and (min-width: $desktop) {
    padding-top: 10rem;
    padding-bottom: 24rem;
    p {
      font-size: 3rem;
      margin-top: 13rem;
    }
  }
}

//-------------------------------------------------------------------------
// Section Home - your idea
//-------------------------------------------------------------------------

.your-idea {
  position: relative;
  padding-left: $content-gutter;
  padding-top: $spacing-y * 1.5;
  color: $color-grey-dark;

  h2 { padding-bottom: $spacing-y; }

  p:last-of-type { margin-top: $spacing-y; }

  a { margin-top: 2em; }

  figure {
    right: $content-gutter * -2;
    bottom: 0;
  }

  @media screen and (min-width: $tablet) {
    .container { @include paddingY($spacing-y * 5); }
    p { width: 42%; }

    figure {
      position: absolute;
      top: -120px;
      right: -200px;
      width: 820px;
      z-index: 10;
    }
  }

  @media screen and (min-width: 888px) {
    //@include debug(blue);
    p { width: 50%; }
  }
}

//-------------------------------------------------------------------------
// Section Home - Your vision / Your expertise
//-------------------------------------------------------------------------

.vision-expertise {
  background: url(/images/bg-vision-expertise.jpg) top center no-repeat;
  background-color: $black;
  background-size: cover;

  @media screen and (min-width: $tablet) {
    background-image: none;
  }
}

//-------------------------------------------------------------------------
// Section Home - your vision
//-------------------------------------------------------------------------

.your-vision {
  @include paddingX($content-gutter);
  @include paddingY($content-gutter * 4);

  h2, p { position: relative; }

  h2 {
    @include title-lg;
    margin-bottom: $spacing-y;
  }

  .large-titled-box {
    position: relative;

    &.align-right {
      text-align: right;
    }
  }

  // large L
  .decoration { display: none; }

  img { display: none; }

  @media screen and (min-width: $tablet) {
    padding-top: $spacing-y * 8.85;
    padding-bottom: $spacing-y * 6;

    .container {
      display: flex;

      figure {
        //@include debug;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .large-titled-box { max-width: 55%; }
    h2 { margin-bottom: $spacing-y; }
    img { display: inline-block; }
  }

  @media screen and (min-width: $desktop) {
    padding-top: 10rem;
    .large-titled-box p { max-width: 46%; }

    .decoration {
      display: inline-block;
      width: 174px;
      position: absolute;
      top: -50px;
      left: 48%;
      //z-index: 2;
    }
  }
}

//-------------------------------------------------------------------------
// Section Home - your expertise
//-------------------------------------------------------------------------

.your-expertise {
  padding-top: $spacing-y * 2;
  padding-bottom: 5rem;
  @include paddingX($content-gutter);
  position: relative;

  h2, p {
    position: relative;
    z-index: 2;
  }

  .large-titled-box { position: relative; }

  h2 {
    @include title-lg;
    margin-bottom: $spacing-y;
  }

  .decoration { display: none; }

  // large L inverted
  p { margin-left: auto; }

  img { display: none; }

  @media screen and (min-width: $tablet) {
    text-align: right;

    .container {
      display: flex;

      figure {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50%;

      }
    }
    h2 { margin-bottom: $spacing-y; }
    p { max-width: 90%; }
    img { display: inline-block; }
    .large-titled-box { margin-left: auto; }
  }

  @media screen and (min-width: $desktop) {

    p { max-width: 48%; }

    // large L inverted
    .decoration {
      display: inline-block;
      width: 174px;
      position: absolute;
      top: -20px;
      left: 14%;
    }
  }
}
