%heading {
  font-family: $font-primary-bold;
  font-weight: bold;
}

h1,
h2,
h3,
h4 {
  @extend %heading;
}

h1 {
  font-size: $font-size-lg;
}

h2 {
  font-size: $font-size-lg;
}


.title-2lines {
  font-size: $font-size-default;
  letter-spacing: 1px;
  font-family: $font-primary-bold;
  text-transform: uppercase;

  small {
    display: block;
    font-size: $font-size-sm;
  }

  @media screen and (min-width: $tablet) {
    font-size: 2rem;

    small {
      font-size: $font-size-lg;
    }
  }
}
