@charset "UTF-8";
// styling structure
// https://sass-guidelin.es/#the-7-1-pattern
// https://github.com/HugoGiraudel/sass-boilerplate


// 1. Configuration and helpers
@import 'abstracts/variables';
@import 'abstracts/mixins';


// 2. Vendors
@import 'vendors/reset';
@import 'vendors/normalize';

// 3. Base stuff
@import 'base/base';
@import 'base/typography';

// 4. Layout-related sections
@import 'layout/navigation';
@import 'layout/footer';


// 5. Components
@import 'components/animations';
@import 'components/buttons';
@import 'components/boxes';

// 6. Page-specific styles
@import 'pages/home';
@import 'pages/vision';
@import 'pages/demarche';
@import 'pages/portfolio';
@import 'pages/portfolio-single';
@import 'pages/contact';
@import 'pages/404';
@import 'pages/form-success';
@import 'pages/form-fail';
