// layout/_navigation.scss

.main-nav {
  $padding-top: $header-h-mobile;
  position: relative;
  @include paddingY($padding-top);

  .logo {
    display: block;
    margin-bottom: $spacing-y;
  }

  .lang-switcher {
    position: absolute;
    top: $padding-top + .4rem;
    right: $content-gutter;
    text-transform: uppercase;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
  }

  @media screen and (min-width: $tablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include paddingY($header-h-desktop);

    .logo {
      margin: 0;
      width: 125px;
    }

    ul {
      flex-basis: 50%;
    }

    .lang-switcher {
      position: static;
    }
  }

  @media screen and (min-width: $desktop) {
    ul {
      flex-basis: 40%;
    }
  }
}

.nav-link:link,
.nav-link:visited {
  color: $color-grey-light;
  text-decoration: none;
  padding-bottom: 0.1rem;
  font-family: $font-primary-bold;
  font-size: $font-size-sm;
  letter-spacing: 1px;

  &:hover,
  &:focus,
  &:active {
    border-bottom: 2px solid $white;
  }

  &.active{
    border-bottom: 2px solid $color-primary;
  }

  @media screen and (min-width: $tablet) {
    font-size: $font-size-md;
  }
}

#breadcrumbs {
  margin-bottom: 1rem;

  @media screen and (min-width: $tablet) {
    margin-bottom: 2rem;
  }

  a {
    text-decoration: none;
    color: $color-primary;
  }

  span {
    color: $white;
  }
}
